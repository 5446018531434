<template>
  <div style="width: 80%; margin: 20px auto 0">
    <div>
      <p style=";font-size: 25px;margin-left: 20px;padding-top: 20px;">{{ name }}您好，您近期的工资数据如下:</p>
    </div>

    <div style="display: flex;width: 100%">
      <!--    工资环比-->
      <div>
      <span style=";font-size: 20px;margin-left: 20px">
        1、工资实发数据环比
      </span>
        <div id="myEcharts" style="width: 800px;height: 350px;background-color: white;margin-bottom: 20px;margin-right: 20px;margin-top: 10px">
        </div>
      </div>
      <!--    工资同比-->
      <div>
      <span style=";font-size: 20px;margin-left: 40px;">
        2、工资实发数据历年同比
      </span>
        <div id="myEchartsTong" style="width: 800px;height: 350px;background-color: white;margin-left: 20px;margin-top: 10px">
        </div>
      </div>
    </div>

    <div style="display: flex">
      <!--近年来工资情况-->
      <div>
        <span style=";font-size: 20px;margin-left: 20px;">
          3、近年来工资汇总情况
        </span>
        <span style=";font-size: 18px;">
        (括号内为当年记录月数)
        </span>
        <div id="myEchartsNearAll" style="width: 800px;height: 390px;margin-top: 20px;margin-bottom: 10px;margin-right: 20px;background-color: white">
        </div>
      </div>

      <!--近年来工资情况-->
      <div>
        <span style=";font-size: 20px;margin-left: 40px;">
          4、近年来月度平均工资收入
        </span>
        <span style=";font-size: 18px;">
        (括号内为当年记录月数)
        </span>
        <div id="myEchartsNear" style="width: 800px;height: 390px;margin-top: 20px;margin-bottom: 10px;margin-left: 20px;background-color: white">
        </div>
      </div>
    </div>
    <!--    近一年工资明细-->
    <div>
      <span style=";font-size: 20px;margin-left: 20px;margin-top: 40px">
        5、近一年工资明细
      </span>
      <div style="width: 1640px;margin-top: 30px;padding-bottom: 50px">
        <vxe-table
            border
            resizable
            :align="allAlign"
            :data="userSalarys"
            size="small"
            highlight-hover-row
            :row-class-name="rowClassName"
            ref="xTable1"
            :export-config="{}"
        >
          <vxe-column field="salaryMonth" title="月份" width="78" fixed="left"></vxe-column>
          <vxe-column field="gangWeiGongZiBiaoZhun" title="岗位工资标准" width="78" fixed="left"></vxe-column>
          <vxe-column field="tiaoJieXiShu" title="调节系数" width="78" fixed="left"></vxe-column>
          <vxe-column field="gangWeiGongZi" title="岗位工资" width="78"></vxe-column>
          <vxe-column field="nianGongGongZi" title="年工工资" width="78"></vxe-column>
          <vxe-column field="jiShuZhiChengJinTie" title="技术职称津贴" width="78"></vxe-column>
          <vxe-column field="liuDongShiGongJinTie" title="流动施工津贴" width="78"></vxe-column>
          <vxe-column field="suiDaoShiGongBuTie" title="隧道施工补贴" width="78"></vxe-column>
          <vxe-column field="zhiYeZiGeZhengShuJinTie" title="执业资格证书注册人员津贴" width="78"></vxe-column>
          <vxe-column field="yiDongTongXinFei" title="移动通信费" width="78"></vxe-column>
          <vxe-column field="jiaBanGongZi" title="加班工资" width="78"></vxe-column>
          <vxe-column field="jiTuanGongSiZhuanJiangLi" title="集团公司转奖励" width="78"></vxe-column>
          <vxe-column field="danXiangJiangLi" title="单项奖励" width="78"></vxe-column>
          <vxe-column field="yingFaHeJi" title="应发合计" width="78"></vxe-column>
          <vxe-column field="yangLaoBaoXian" title="养老保险" width="78"></vxe-column>
          <vxe-column field="zhuFangGongJiJin" title="住房公积金" width="78"></vxe-column>
          <vxe-column field="qiYeNianJin" title="企业年金" width="78"></vxe-column>
          <vxe-column field="yiLiaoBaoXian" title="医疗保险" width="78"></vxe-column>
          <vxe-column field="shiYeBaoXian" title="失业保险" width="78"></vxe-column>
          <vxe-column field="geRenSuoDeShui" title="个人所得税" width="78"></vxe-column>
          <vxe-column field="shiFaHeJi" title="实发合计" width="78"></vxe-column>
        </vxe-table>
      </div>
    </div>
    <div>
    </div>
  </div>
</template>

<script>
import {Decrypt, Encrypt} from "@/utils/secret";

export default {
  name: "show_salary",
  data(){
    return{
      allAlign: 'center',
      userSalarys: [],
      name: '',
      com: true
    }
  },
  created() {

  },
  mounted() {
    const url = window.location.href;             //获取当前url
    //const dz_url = url.split('#')[1];    //获取#/之前的字符串
    const cs = url.split('?')[1];     //获取?之后的参数字符串
    const cs_arr = cs.split('&');        //参数字符串分割为数组
    this.name = Decrypt(cs_arr[0].split("=")[1]);
    this.getUserSalary(Decrypt(cs_arr[0].split("=")[1]), Decrypt(cs_arr[1].split("=")[1]), Decrypt(cs_arr[2].split("=")[1]));
    this.getUserSalaryTong(Decrypt(cs_arr[0].split("=")[1]), Decrypt(cs_arr[1].split("=")[1]), Decrypt(cs_arr[2].split("=")[1]));
    this.getUserSalaryNear(Decrypt(cs_arr[0].split("=")[1]), Decrypt(cs_arr[1].split("=")[1]), Decrypt(cs_arr[2].split("=")[1]));
    this.getUserSalaryNearAll(Decrypt(cs_arr[0].split("=")[1]), Decrypt(cs_arr[1].split("=")[1]), Decrypt(cs_arr[2].split("=")[1]));
  },
  methods:{
    getUserSalary(name, month, bdst){
      let x = [];
      let y = [];
      // 基于准备好的dom，初始化echarts实例
      const myChart = this.$echarts.init(document.getElementById('myEcharts'));
      //数据加载完之前先显示一段简单的loading动画
      myChart.showLoading();
      this.axios.post('/salary/showsal', (response) => {
        this.userSalarys = response.obj;
        console.log(this.userSalarys)
        if (response.obj.length !== 0){
          myChart.hideLoading();    //隐藏加载动画
          for (let i = 0; i < response.obj.length; i++){
            x.push(response.obj[i].salaryMonth);
            y.push(response.obj[i].shiFaHeJi);
          }
          // 绘制图表
          myChart.setOption({
            xAxis: {
              type: 'category',
              data: x,
              axisLabel:{
                interval: 0
              }
            },
            yAxis: {
              type: 'value',
              axisLine:{
                show:true //y轴线消失
              },
            },
            series: [
              {
                data: y,
                type: 'bar',
                barWidth: 50,
                barGap: '30%',
                color: '#91cc75',
                itemStyle: {
                  normal: {
                    label: {
                      show: true, //开启显示
                      position: 'top', //在上方显示
                      textStyle: {
                        //数值样式
                        color: 'black',
                        fontSize: 12,
                      },
                    },
                  }
                },
              }
            ]
          });
        }
      },{
        name: name,
        month: month,
        bdst: bdst
      })
    },
    getUserSalaryTong(name, month, bdst){
      let x = [];
      let y = [];
      // 基于准备好的dom，初始化echarts实例
      const myEchartsTong = this.$echarts.init(document.getElementById('myEchartsTong'));
      //数据加载完之前先显示一段简单的loading动画
      myEchartsTong.showLoading();
      this.axios.post('/salary/showsaltong', (response) => {
        if (response.obj.length !== 0){
          myEchartsTong.hideLoading();    //隐藏加载动画
          for (let i = 0; i < response.obj.length; i++){
            x.push(response.obj[i].salaryMonth);
            y.push(response.obj[i].shiFaHeJi)
          }
          // 绘制图表
          myEchartsTong.setOption({
            xAxis: {
              type: 'category',
              data: x,
              axisLabel:{
                interval: 0
              }
            },
            yAxis: {
              type: 'value',
              axisLine:{
                show:true //y轴线消失
              },
            },
            series: [
              {
                data: y,
                type: 'bar',
                barWidth: 50,
                barGap: '30%',
                color: '#ee6666',
                itemStyle: {
                  normal: {
                    label: {
                      show: true, //开启显示
                      position: 'top', //在上方显示
                      textStyle: {
                        //数值样式
                        color: 'black',
                        fontSize: 12,
                      },
                    },
                  },
                },
              }
            ]
          });
        }
      },{
        name: name,
        month: month,
        bdst: bdst
      })
    },
    getUserSalaryNear(name, month, bdst){
      let x = [];
      let y = [];
      // 基于准备好的dom，初始化echarts实例
      const myEchartsNear = this.$echarts.init(document.getElementById('myEchartsNear'));
      //数据加载完之前先显示一段简单的loading动画
      myEchartsNear.showLoading();
      this.axios.post('/salary/showsalnear', (response) => {
        if (response.obj.length !== 0){
          myEchartsNear.hideLoading();    //隐藏加载动画
          for (let i = 0; i < response.obj.length; i++){
            x.push(response.obj[i]);
            y.push(response.obj2[i])
          }
          // 绘制图表
          myEchartsNear.setOption({
            xAxis: {
              type: 'category',
              data: x,
              axisLabel:{
                interval: 0
              }
            },
            yAxis: {
              type: 'value',
              axisLine:{
                show:true //y轴线消失
              },
            },
            series: [
              {
                data: y,
                type: 'bar',
                barWidth: 50,
                barGap: '30%',
                color: '#fc8452',
                itemStyle: {
                  normal: {
                    label: {
                      show: true, //开启显示
                      position: 'top', //在上方显示
                      textStyle: {
                        //数值样式
                        color: 'black',
                        fontSize: 12,
                      },
                    },
                  },
                },
              }
            ]
          });
        }
      },{
        name: name,
        month: month,
        bdst: bdst
      })
    },
    getUserSalaryNearAll(name, month, bdst){
      let x = [];
      let y = [];
      // 基于准备好的dom，初始化echarts实例
      const myEchartsNearAll = this.$echarts.init(document.getElementById('myEchartsNearAll'));
      //数据加载完之前先显示一段简单的loading动画
      myEchartsNearAll.showLoading();
      this.axios.post('/salary/showsalnearall', (response) => {
        if (response.obj.length !== 0){
          myEchartsNearAll.hideLoading();    //隐藏加载动画
          for (let i = 0; i < response.obj.length; i++){
            x.push(response.obj[i]);
            y.push(response.obj2[i])
          }
          // 绘制图表
          myEchartsNearAll.setOption({
            xAxis: {
              type: 'category',
              data: x,
              axisLabel:{
                interval: 0
              }
            },
            yAxis: {
              type: 'value',
              axisLine:{
                show:true //y轴线消失
              },
            },
            series: [
              {
                data: y,
                type: 'bar',
                barWidth: 50,
                barGap: '30%',
                color: '#ea7ccc',
                itemStyle: {
                  normal: {
                    label: {
                      show: true, //开启显示
                      position: 'top', //在上方显示
                      textStyle: {
                        //数值样式
                        color: 'black',
                        fontSize: 12,
                      },
                    },
                  },
                },
              }
            ]
          });
        }
      },{
        name: name,
        month: month,
        bdst: bdst
      })
    },
    rowClassName ({ row }) {
      return 'row-green';
    }
  }
}
</script>

<style scoped>
</style>